import React, { useEffect, useState } from "react";
import $ from "jquery";
import moment from "moment";
import PieChart from "../../../components/charts/PieChart";

const Data = () => {

    const [loading, setLoading] = useState(true);
    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);

    const [datatable] = useState({
        credit: false
    });

    useEffect(() => {
        setLoading(false);
    }, []);

    const creditDatatable = () => {
        if(datatable.driver) return false;
        datatable.driver = true;
        $('#credit_table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Credit Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10
        });
    }

    if(!loading) {
        return (
            <div className="content-body">
                <div className="container-fluid pd-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item"><a href="#">Menu</a></li>
                                    <li className="breadcrumb-item"><a href="#">Collector</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Details</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="d-none d-md-block">
                            <span className="badge badge-success tx-white">Active</span>
                        </div>
                    </div>

                    <div className="d-sm-flex align-items-center justify-content-between">
                        <div className="media">
                            <div className="wd-100 ht-100 bg-ui-04 rounded d-flex align-items-center justify-content-center bg-white">
                                <span className="link-03 lh-0 text-center"><i style={{fontSize: 50}} className="icon ion-md-camera"></i><p className="text-center tx-color-03 mt-2 tx-13">No Photo</p></span>
                            </div>
                            <div className="media-body pd-l-25 my-auto">
                                <h5 className="mg-b-5 text-capitalize">Michael Chuba</h5>
                                <p className="mg-b-3 text-capitalize">
                                    <span className="tx-medium tx-color-02 text-capitalize">Male</span><br />
                                    Zambia
                                </p>
                                <span className="d-block tx-13 tx-color-03">0975502028</span>
                            </div>
                        </div>
                        <div className="d-none d-md-block">
                            <button className="btn btn-sm pd-x-15 btn-brand-02 btn-uppercase mg-l-5 outline-none">
                                <i className="wd-10 mg-r-5 fa fa-truck"></i> Comment
                            </button>
                            <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none">
                                <i className="wd-10 mg-r-5 fa fa-truck"></i> Amortization
                            </button>
                            <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none">
                                <i className="wd-10 mg-r-5 fa fa-truck"></i> Disbursement Info
                            </button>
                            <button className="btn btn-sm pd-x-15 btn-secondary btn-uppercase mg-l-5 outline-none">
                                <i className="wd-10 mg-r-5 fa fa-truck"></i> Condition Precedent
                            </button>
                        </div>
                    </div>

                    <div className="row row-xs">
                        <div className="col-md-12 mg-t-10">
                            <div className="nav-wrapper mg-b-20 tx-13">
                            <div>
                                    <ul id="nav_basic" className="nav nav-line nav-fill tx-medium">
                                        <li className="nav-item"><a href="#basic " className="nav-link active" data-toggle="tab"><i className="ion-md-information-circle"/> Customer Details</a></li>
                                        <li className="nav-item"><a href="#loan" className="nav-link" data-toggle="tab"><i className="ion-md-locate"/> Loans</a></li>
                                        <li className="nav-item"><a href="#credit" className="nav-link" data-toggle="tab"><i className="ion-md-trash"/> Credit Risk</a></li>
                                        <li className="nav-item"><a href="#previous-loan" className="nav-link" data-toggle="tab" onClick={creditDatatable.bind()}><i className="ion-md-cash"/> Previous Loans</a></li>
                                        <li className="nav-item"><a href="#checklist" className="nav-link" data-toggle="tab"><i className="ion-md-paper-plane"/> Checklist</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div id="basic" className="tab-pane fade active show">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <h3>Basic Info</h3>
                                            <p><strong>Name:</strong> Michael Chuba</p>
                                            <p><strong>Gender:</strong> Male</p>
                                            <p><strong>Date of Birth:</strong> June 30, 1993</p>
                                            <p><strong>Nationality:</strong> Zambia</p>
                                            <p><strong>ID Number:</strong> 24775/10/1</p>
                                            <p><strong>ID Type:</strong> NRC</p>
                                            <p><strong>Marital Status:</strong> Yes</p>
                                            <p><strong>Number of Dependants:</strong> 4</p>
                                            <p><strong>Referral Code:</strong> 5GH677</p>
                                            <p><strong>Last Login:</strong> {moment().format("Do MMM, YYYY")}</p>
                                            <p><strong>Date Created:</strong> {moment().format("Do MMM, YYYY")}</p>
                                            <hr />
                                        </div>

                                        <div className="col-md-12">
                                            <h3>Contact Details</h3>
                                            <p><strong>Phone Number:</strong> 0967747474</p>
                                            <p><strong>Mobile Mobile Number:</strong> 0974466363</p>
                                            <p><strong>Email Address:</strong> micahelchubajr@gmail.com</p>
                                            <p><strong>Street Address:</strong> Libala Stage 3</p>
                                            <p><strong>House Number:</strong> 24775</p>
                                            <p><strong>Area or Township:</strong> Libala</p>
                                            <p><strong>Town:</strong> Lusaka</p>
                                            <p><strong>Province:</strong> Lusaka</p>
                                            <p><strong>Number of year at address:</strong> 5</p>
                                            <p><strong>Accommodation Type:</strong> Rent</p>
                                            <p><strong>Last Login:</strong> {moment().format("Do MMM, YYYY")}</p>
                                            <p><strong>Date Created:</strong> {moment().format("Do MMM, YYYY")}</p>
                                            <hr />
                                        </div>

                                        <div className="col-md-12">
                                            <h3>Employment Info</h3>
                                            <p><strong>Status:</strong> Employed</p>
                                            <p><strong>Occupation:</strong> Civil Servant</p>
                                            <p><strong>Sector:</strong> Energy</p>
                                            <p><strong>Employer:</strong> Home Affair</p>
                                            <p><strong>Employer Number:</strong> 00477333</p>
                                            <p><strong>Department:</strong> Police</p>
                                            <p><strong>Employment Type:</strong> Contract</p>
                                            <p><strong>Number of Years at job:</strong> 5</p>
                                            <p><strong>Employer Contact Person:</strong> Sophie Phiri</p>
                                            <p><strong>Employer Contact Number:</strong> 0887476474</p>
                                            <p><strong>Employer Contact Email:</strong> sophie@gmail.com</p>
                                            <p><strong>Employer Contact Designation:</strong> Human Resources</p>
                                            <p><strong>Supervisor Contact Person:</strong> Sophie Phiri</p>
                                            <p><strong>Supervisor Contact Number:</strong> 0887476474</p>
                                            <p><strong>Supervisor Contact Email:</strong> sophie@gmail.com</p>
                                            <p><strong>Supervisor Contact Designation:</strong> Human Resources</p>
                                            <p><strong>Contract:</strong> 5</p>
                                            <p><strong>Number of year at address:</strong> 5</p>
                                            <p><strong>Work - Street Address:</strong> N/A</p>
                                            <p><strong>Work - Physical Address:</strong> N/A</p>
                                            <p><strong>Work - Area or Township:</strong> N/A</p>
                                            <p><strong>Work - Town:</strong> N/A</p>
                                            <p><strong>Work - Province:</strong> N/A</p>
                                            <p><strong>When is Pay Received:</strong> 28</p>

                                            <p><strong>Bank:</strong> FNB</p>
                                            <p><strong>Bank Account Number:</strong> N/A</p>
                                            <p><strong>Bank Branch:</strong> N/A</p>
                                            <hr />
                                        </div>

                                        <div className="col-md-12">
                                            <h3>References</h3>
                                            <p><strong>Next of Kin:</strong> Lucy Chuba</p>
                                            <p><strong>Next of Kin Phone:</strong> 0974466363</p>
                                            <p><strong>Next of Kin Email:</strong> micahelchubajr@gmail.com</p>
                                            <p><strong>Next of Kin Address:</strong> Libala Stage 3</p>
                                            <p><strong>Relation with Next of Kin:</strong> Mother</p>
                                            <p><strong>Reference</strong></p>
                                            <p><strong>Reference Phone:</strong> 0987466563</p>
                                            <p><strong>Reference Email:</strong> lulu@gmail.com</p>
                                            <p><strong>Reference Address:</strong> Libala</p>
                                            <p><strong>Relationship with Reference:</strong> Sister</p>
                                            <hr />
                                        </div>

                                        <div className="col-md-12">
                                            <h3>Supporting Document</h3>
                                            <p><strong>Copy of ID:</strong> <a href="#">Download</a></p>
                                            <p><strong>User Holding ID:</strong> <a href="#">Download</a></p>
                                        </div>
                                    </div>
                                </div>

                                <div id="loan" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <h3>Loan Details</h3>
                                            <p><strong>Loan Number:</strong> 56700</p>
                                            <p><strong>Disbursement Status:</strong> Pending</p>
                                            <p><strong>Eligible Amount:</strong> K1,546.67</p>
                                            <p className="tx-danger"><strong className="tx-color-01">Applied for Amount:</strong> K50,000.00</p>
                                            <p><strong>Service Fee:</strong> K5,000.00</p>
                                            <p><strong>Disbursement Amount:</strong> K45,000.00</p>
                                            <p><strong>Tenure:</strong> 60</p>
                                            <p><strong>Monthly Interest:</strong> 2.5</p>
                                            <p><strong>Monthly Repayment:</strong> K1,656.00</p>
                                            <p><strong>Repayment Date:</strong> {moment().format("Do MMM, YYYY")}</p>
                                            <p><strong>Total Interest:</strong> K47,000.00</p>
                                            <p><strong>Total Loan Value:</strong> K97,000.00</p>
                                            <p><strong>Application Date:</strong> {moment().format("Do MMM, YYYY")}</p>
                                            <p><strong>Selected Loan Type:</strong> Civil Servant Loan</p>
                                            <p><strong>Principle Maratorium:</strong> No</p>
                                            <p><strong>Default Status:</strong> Not Defaulted</p>
                                            <p><strong>Reason For Borrowing:</strong> Refinancing</p>
                                            <hr />
                                        </div>

                                        <div className="col-md-12">
                                            <h3>Supporting Document</h3>
                                            <p><strong>Pay Slips:</strong> <a href="#">Download</a></p>
                                            <p><strong>Bank Statements:</strong> <a href="#">Download</a></p>
                                        </div>
                                    </div>
                                </div>

                                <div id="credit" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <h3>Recommendation</h3>
                                            <p className="tx-danger"><strong className="tx-color-01">Score (out of 100):</strong> 51</p>
                                            <p className="tx-danger"><strong className="tx-color-01">Decision:</strong> High Risk</p>
                                        </div>
                                    </div>
                                </div>

                                <div id="previous-loan" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center justify-content-between">
                                                    <h6 className="mg-b-0">Previous Loan</h6>
                                                    <div className="d-flex tx-18">
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"
                                                           onClick={() => $("#credit_table").DataTable().ajax.reload()}><i
                                                            className="icon ion-md-refresh"></i></a>
                                                        <a href="javascript:void(0)" className="link-03 lh-0 mg-l-10"><i
                                                            className="icon ion-md-add"></i></a>
                                                    </div>
                                                </div>
                                                <div className="card-body table-responsive">
                                                    <table id="credit_table" className="table table-hover" style={{ width: "100%" }}>
                                                        <thead>
                                                        <tr>
                                                            <th className="wd-20p">Amount</th>
                                                            <th className="wd-20p">Loans Status</th>
                                                            <th className="wd-20p">Settled</th>
                                                            <th className="wd-20p">Date</th>
                                                            <th className="wd-20p">Action</th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="checklist" className="tab-pane fade">
                                    <div className="row row-sm">
                                        <div className="col-md-12">
                                            <PieChart />
                                            <div className="mx-auto">
                                                <h5>Due Diligence</h5>
                                                <p><input type="checkbox" name="vehicle1" value="Bike" /> KYC Checklist - 25 / 45 Complete</p>
                                                <div className="card pd-t-15-f pd-x-15 mt-3">
                                                    <p><input type="checkbox" name="check1" value="check1" /> Recurring
                                                        customer and not defaulted</p>
                                                </div>

                                                <div className="card pd-t-15-f pd-x-15 mt-3">
                                                    <p><input type="checkbox" name="check1" value="check1" /> Recurring
                                                        customer and not defaulted</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content tx-14">
                                <div className="modal-header">
                                    <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="mg-b-0 data-message">Empty</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" id="delete-data" className="btn btn-success btn-block">Yes, Delete</button>
                                    <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalCollector" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Garbage Collector" : "New Garbage Collector"}</h4><p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} garbage collector information.</p></div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="collector_form" className="parsley-style-1">
                                        <div id="nameWrapper" className="form-group parsley-input">
                                            <label>Company Name<span className="tx-danger">*</span></label>
                                            <input id="name" type="text" className="form-control"
                                                   placeholder="Enter company name"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#nameWrapper" required/>
                                        </div>

                                        <div id="logoWrapper" className="form-group parsley-input">
                                            <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Company Logo</label>
                                            <input id="logo_image" type="file" className="form-control"
                                                   data-parsley-filemaxmegabytes="1"
                                                   data-parsley-trigger="change"
                                                   data-parsley-filemimetypes="image/jpeg, image/jpg, image/png"
                                                   accept="image/jpeg, image/jpg, image/png"
                                                   data-parsley-class-handler="#logoWrapper"
                                            />
                                        </div>

                                        <div id="emailWrapper" className="form-group parsley-input">
                                            <label>Email<span className="tx-danger">*</span></label>
                                            <input id="email" type="email" className="form-control"
                                                   placeholder="Enter email address"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#emailWrapper" required/>
                                        </div>

                                        <div id="mobileWrapper" className="form-group parsley-input">
                                            <label>Mobile Number<span className="tx-danger">*</span></label>
                                            <input id="mobile" type="number" className="form-control"
                                                   placeholder="Enter mobile number"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#mobileWrapper" required/>
                                        </div>

                                        <div id="addressWrapper" className="form-group parsley-input">
                                            <label>Physical Address<span className="tx-danger">*</span></label>
                                            <input id="address" type="text" className="form-control"
                                                   placeholder="Enter physical address"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#addressWrapper" required/>
                                        </div>

                                        <div id="taxWrapper" className="form-group parsley-input">
                                            <label>Tax ID<span className="tx-danger">*</span></label>
                                            <input id="tax" type="text" className="form-control"
                                                   placeholder="Enter tax ID or TPIN #"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#taxWrapper" required/>
                                        </div>

                                        {stateReady ? <button disabled className="btn btn-success btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : editForm ? <button className="btn btn-success btn-block">Update Collector</button> : <button className="btn btn-success btn-block">Create Collector</button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className="content-body">
            <div className="container d-flex justify-content-center ht-100p">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <p className="mg-t-10">Collecting Data</p>
                </div>
            </div>
        </div>
    )
};

export default Data;
