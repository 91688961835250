import React, { useEffect } from 'react';
import {GET_DATA_LIST} from '../../api';
import {getUserRegistrats, actionUser} from '../../request';
import 'datatables.net';
import 'datatables.net-responsive';
import $ from "jquery";
import {capitalize} from "../../utils/capitalize";
import moment from "moment";
import cogoToast from "cogo-toast";

const Loan = () => {

    document.title = "Loan";

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {
        var table = $('#user').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Loan Report</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_DATA_LIST, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "loans",
                        fieldname: "description",
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return `${capitalize(data.first_name)} ${data.middle_name ? capitalize(data.middle_name) : ""} ${capitalize(data.last_name)}`;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.mobile_number;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return capitalize(data.gender);
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return data.date.string;
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only">
                                    <a href="#" class="nav-link delete_user"><i class="fa fa-trash-alt"></i> Delete</a>
                                </nav>`
                    },
                    "targets": 4
                }
            ]
        });
    })

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Loans</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Loans</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-sm-12 col-lg-12 mg-b-20">
                        <div className="card bg-white-9">
                            <div className="d-flex justify-content-between p-3">
                                <h5 className="tx-normal tx-primary mg-b-0"><strong>ZMW 0.00</strong></h5>
                                <h5 className="tx-normal tx-color-02 mg-b-0"><strong>Number of Loans:</strong> 0</h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3">
                        <div className="card bg-facebook">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">Civil
                                    Servant</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 id="total-users" className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">ZMW
                                        0.00</h3>
                                </div>
                                <span className="tx-11 tx-white mg-b-0 mg-t-5">0%</span>
                            </div>
                            <div className="card-footer py-0 pb-0">
                                <p className="tx-white pt-3"><strong>Number of Loans:</strong> 0</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3">
                        <div className="card bg-df-1">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">Parastatal
                                    Loans</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 id="total-users" className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">ZMW
                                        0.00</h3>
                                </div>
                                <span className="tx-11 tx-white mg-b-0 mg-t-5">0%</span>
                            </div>
                            <div className="card-footer py-0 pb-0">
                                <p className="tx-white pt-3"><strong>Number of Loans:</strong> 0</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3">
                        <div className="card bg-orange">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">Scheme
                                    Loans</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 id="total-users" className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">ZMW
                                        0.00</h3>
                                </div>
                                <span className="tx-11 tx-white mg-b-0 mg-t-5">0%</span>
                            </div>
                            <div className="card-footer py-0 pb-0">
                                <p className="tx-white pt-3"><strong>Number of Loans:</strong> 0</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3">
                        <div className="card bg-danger">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">Special
                                    Force Loans</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 id="total-users" className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">ZMW
                                        0.00</h3>
                                </div>
                                <span className="tx-11 tx-white mg-b-0 mg-t-5">0%</span>
                            </div>
                            <div className="card-footer py-0 pb-0">
                                <p className="tx-white pt-3"><strong>Number of Loans:</strong> 0</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Total Loans</h6>
                                <div className="d-flex tx-18">
                                    <a href="" className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="user" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-10p">Loan Number</th>
                                        <th className="wd-10p">Customer</th>
                                        <th className="wd-10p">Amount</th>
                                        <th className="wd-10p">Monthly Payment</th>
                                        <th className="wd-10p">Tenure</th>
                                        <th className="wd-10p">Interest</th>
                                        <th className="wd-10p">Service Fee</th>
                                        <th className="wd-10p">Balance</th>
                                        <th className="wd-10p">Previous Loan</th>
                                        <th className="wd-10p">Loan Type</th>
                                        <th className="wd-10p">Status</th>
                                        <th className="wd-10p">Paynest Score</th>
                                        <th className="wd-10p">Date Created</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Loan;
