import React from 'react';
import PrivateRoute from 'components/common/PrivateRoute';
import {Switch, Route} from 'react-router-dom';
import PageError from "components/layout/PageError";
import Dashboard from 'pages/authorized/Dashboard';
import AccessControl from "pages/authorized/AccessControl";
import Loan from "pages/authorized/Loan";
import User from "pages/authorized/User";
import Disbursement from "pages/authorized/Disbursement";
import Settlement from "pages/authorized/Settlement";
import PushNotification from "../../pages/authorized/PushNotification";
import UserData from "../../pages/authorized/user/Data";
import Product from "../../pages/authorized/Product";
import Schedule from "../../pages/authorized/Schedule";
import Collection from "../../pages/authorized/Collection";
import Pipeline from "../../pages/authorized/Pipeline";
import Customer from "../../pages/authorized/Customer";

const Private = () => {
    return (
        <div>
            <Switch>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/user" component={User} />
                <PrivateRoute exact path="/customer" component={Customer} />
                <PrivateRoute exact path="/loan" component={Loan} />
                <PrivateRoute exact path="/disbursement" component={Disbursement} />
                <PrivateRoute exact path="/settlement" component={Settlement} />
                <PrivateRoute exact path="/schedule" component={Schedule} />

                <PrivateRoute exact path="/user/:id" component={UserData} />

                <PrivateRoute exact path="/collection" component={Collection} />
                <PrivateRoute exact path="/pipeline" component={Pipeline} />

                <PrivateRoute exact path="/error" component={PageError} />
                <PrivateRoute exact path="/product" component={Product} />
                <PrivateRoute exact path="/push_notification" component={PushNotification} />
                <PrivateRoute exact path="/access_control" component={AccessControl} />
                <Route component={PageError} />
            </Switch>
        </div>
    )
};

export default Private;
