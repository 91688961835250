const API_URL = 'https://server.paynestloan.com/admin';
// const API_URL = 'http://127.0.0.1:7789/admin';

exports.SIGNIN_URL = `${API_URL}/sign-in`;
exports.GET_COLLECTION_DATA = `${API_URL}/collection-data`;
exports.GET_COLLECTION_AGGREGATE_DATA = `${API_URL}/collection-aggregate-data`;
exports.CUSTOMER_URL = `${API_URL}/customer`;

exports.ACTION_ADMIN_URL = `${API_URL}/action-admin`;
exports.ACTION_PERSONAL_CLIENT_URL = `${API_URL}/action-personal-client`;
exports.ACTION_CLIENT_URL = `${API_URL}/customer`;
exports.ACTION_CORPORATE_CLIENT_URL = `${API_URL}/action-corporate-client`;

exports.GET_USER_STATISTICS_URL = `${API_URL}/user-statistics`;
exports.GET_REGISTERED_COUNT_URL = `${API_URL}/get-registered-counter`;
exports.PUSH_NOTIFICATION = `${API_URL}/push-notification`;
exports.ACTION_NOTIFICATION = `${API_URL}/action-notification`;
exports.ACTION_ORDER_URL = `${API_URL}/action-order`;
exports.ACTION_PRODUCT_URL = `${API_URL}/action-product`;
exports.GET_MENU_LIST = `${API_URL}/get-menu-list`;
exports.CONFIRM_ORDER = `${API_URL}/confirm-order`;
exports.DELETE_USER_URL = `${API_URL}/delete-user`;
exports.ACTION_ADMIN_USER_URL = `${API_URL}/action-admin-user`;
exports.ACTION_CATEGORY_URL = `${API_URL}/action-category`;
exports.ADD_ADMIN_USER_URL = `${API_URL}/add-admin-user`;
exports.ACTION_BUSINESS_URL = `${API_URL}/action-business`;
exports.ACTION_MENU_URL = `${API_URL}/action-menu`;
exports.GET_DATA_LIST = `${API_URL}/get-data-list`;
exports.CREATE_PRODUCT_URL = `${API_URL}/create-product`;
exports.GET_ORDER_LIST = `${API_URL}/get-order-list`;
exports.SEARCH_ORDER_URL = `${API_URL}/search-order-number`;
exports.SEARCH_PRODUCT_URL = `${API_URL}/search-product`;
exports.GET_PRODUCT_LIST_URL = `${API_URL}/get-product-list`;
exports.GET_PRODUCT_LIST = `${API_URL}/get-product`;
exports.FETCH_URL = `${API_URL}/image/`;
