import axios from 'axios';
import {
    PUSH_NOTIFICATION,
    ACTION_NOTIFICATION,
    ACTION_ADMIN_USER_URL,
    ACTION_CATEGORY_URL,
    ACTION_ORDER_URL,
    GET_PRODUCT_LIST_URL,
    SEARCH_ORDER_URL,
    CONFIRM_ORDER,
    ACTION_PRODUCT_URL,
    SEARCH_PRODUCT_URL,
    ACTION_BUSINESS_URL,
    ACTION_MENU_URL,
    ACTION_ADMIN_URL
} from "../api";
import errorHandler from "../utils/errorHandler";
import cogoToast from "cogo-toast";
import setAuthToken from "../utils/setAuthToken";
import {SET_AUTH_USER} from "../stores/auth";
import jwt_decode from "jwt-decode";

const options = {
    position: "top-right",
    hideAfter: 5
};

export const sendPushNotification = (data) => {
    return axios.post(PUSH_NOTIFICATION, {
        topic: data.topic,
        title: data.title,
        raw_message: data.raw_message
    }).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
};

export const actionNotification = (data) => {
    return axios.post(ACTION_NOTIFICATION, {data}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
}

export const searchProduct = (query) => {
    return axios.post(SEARCH_PRODUCT_URL, {query: query}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
}

export const editProduct = (id, name, price, description, type) => {
    return axios.post(ACTION_PRODUCT_URL, {id, name, price, description, type}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
};

export const addProduct = (id, name, price, description, buffer, type, formData, config) => {
    return axios.post(ACTION_PRODUCT_URL, formData, config).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
};

export const deleteProduct = (id, _id, type) => {
    return axios.post(ACTION_PRODUCT_URL, {id, _id, type}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
};

export const actionProduct = (data, config) => {
    return axios.post(ACTION_PRODUCT_URL, data, config).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((err) => {
        return {
            data: err,
            error: true
        }
    });
}

export const searchOrderNumber = (query) => {
    return axios.post(SEARCH_ORDER_URL, {query: query}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
}

export const getProduct = (pageSize, id) => {
    return axios.post(GET_PRODUCT_LIST_URL, {pageSize, id}).then((response) => {
        return {
            data: response.data.product,
            total: response.data.total,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
}

export const confirmOrder = (data) => {
    console.log(data);
    return axios.post(CONFIRM_ORDER, {query: data}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
};

export const updateMenu = (id, name, position, enable, action) => {
    console.log(id);
    return axios.post(ACTION_MENU_URL, {id, name, position, enable, action}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            data: error,
            error: true
        }
    });
};

export const actionMenu = (data, config) => {
    return axios.post(ACTION_MENU_URL, data, config).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((err) => {
        return {
            data: err,
            error: true
        }
    });
};

export const addMenu = (id, name, action) => {
    return axios.post(ACTION_MENU_URL, {id, name, action}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            data: error,
            error: true
        }
    });
};

export const deleteMenu = (id, action) => {
    return axios.post(ACTION_MENU_URL, {id, action}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            data: error,
            error: true
        }
    });
}

export const actionOrder = (data) => {
    return axios.post(ACTION_ORDER_URL, {data}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
}

export const addCategory = (name, action) => {
    return axios.post(ACTION_CATEGORY_URL, {name, action}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            data: error,
            error: true
        }
    });
};

export const editCategory = (id, name, position, enable, action) => {
    return axios.post(ACTION_CATEGORY_URL, {id, name, position, enable, action}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            data: error,
            error: true
        }
    });
};

export const deleteCategory = (id, action) => {
    return axios.post(ACTION_CATEGORY_URL, {id, action}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            data: error,
            error: true
        }
    });
}

export const addAdminUser = (data, action) => {
    return axios.post(ACTION_ADMIN_USER_URL, {data, action}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        return {
            data: error,
            error: true
        }
    });
};

export const actionAdmin = (raw) => {
    return axios.post(ACTION_ADMIN_URL, raw).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            data: error,
            error: true
        }
    });
};

export const editAdminUser = (data, action) => {
    return axios.post(ACTION_ADMIN_USER_URL, {data, action}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        return {
            data: error,
            error: true
        }
    });
}

export const deleteAmin = (id, action) => {
    return axios.post(ACTION_ADMIN_USER_URL, {id, action}).then((response) => {
        return {
            data: response.data,
            error: false
        }
    }).catch((err) => {
        return {
            data: err.response.data.error,
            error: true
        }
    });
};

export const updateBusiness = (id, name, address, tpin, phone, password, type) => {
    return function(dispatch) {
        return axios.post(ACTION_BUSINESS_URL, {id, name, address, tpin, phone, password, type}).then((response) => {
            cogoToast.success(response.data.payload, options);
            //Set token to localStorage
            localStorage.setItem('jwtToken', response.data.token);

            //Set token to Auth header
            setAuthToken(response.data.token);

            //Set current user
            dispatch(SET_AUTH_USER({user: jwt_decode(response.data.token)}));
            return {
                error: false
            }
        }).catch((error) => {
            errorHandler(error, "top-right");
            return {
                error: true
            }
        });
    }
};

export const updatePassword = (id, c_password, n_password, type) => {
    return axios.post(ACTION_BUSINESS_URL, {id, c_password, n_password, type}).then((response) => {
        cogoToast.success(response.data.payload, options);
        return {
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
};
