import React, { useEffect } from 'react';
import {GET_DATA_LIST} from '../../api';
import {getUserRegistrats, actionUser} from '../../request';
import 'datatables.net';
import 'datatables.net-responsive';
import $ from "jquery";
import {capitalize} from "../../utils/capitalize";
import moment from "moment";
import cogoToast from "cogo-toast";

const User = () => {

    document.title = "User";

    const token = localStorage.getItem('jwtToken');

    const handleShowModal = () => {

    }

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Users</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Users</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-user-plus"></i> Add
                            New
                        </button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-sm-6 col-lg-4">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total
                                Registered Users</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-users" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">-</h3>
                            </div>
                            <span className="tx-11 tx-color-02 mg-b-0 mg-t-5">Since 12th June, 2019</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-lg-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Registered Users Today</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="daily-active" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">-</span>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Registered Users Monthly</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="montly-active" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span id="month-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">-</span>
                        </div>
                    </div>

                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Total Users</h6>
                                <div className="d-flex tx-18">
                                    <a href="" className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="user" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Phone</th>
                                        <th className="wd-25p">Email</th>
                                        <th className="wd-15p">Date Created</th>
                                        <th className="wd-15p">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default User;
