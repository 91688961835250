import React, { useEffect } from "react";
import 'datatables.net';
import 'datatables.net-responsive';
import AreaChart from "../../components/charts/AreaChart";
import PieChart from "../../components/charts/PieChart";
import $ from "jquery";
import { GET_DATA_LIST } from "../../api";
import { capitalize } from "../../utils/capitalize";

const Collection = () => {

    document.title = "Collection";

    useEffect(() => {
        var table = $('#user').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Loan Report</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10
        });
    })

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Collection</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Collection</h4>
                    </div>
                </div>

              <div className="row row-xs">

                <div className="col-sm-12 col-lg-12 mg-b-20">
                  <div className="card bg-facebook p-3">
                    <div className="row text-center">
                      <div className="col-md-3">
                        <h5 className="tx-normal tx-white mg-b-0 mg-r-5 lh-1"><strong>Actual:</strong> ZMW
                          0.00</h5>
                      </div>

                      <div className="col-md-3">
                        <h5 className="tx-normal tx-white mg-b-0 mg-r-5 lh-1"><strong>Expected:</strong> ZMW
                          0.00</h5>
                      </div>

                      <div className="col-md-3">
                        <h5 className="tx-normal tx-white mg-b-0 mg-r-5 lh-1"><strong>Collection Rate:</strong> 0%</h5>
                      </div>

                      <div className="col-md-3">
                        <h5 className="tx-normal tx-white mg-b-0 mg-r-5 lh-1"><strong>Total Number of Loans:</strong> 0
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                  <div className="card bg-facebook">
                    <div className="card-body">
                      <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">MOU</h6>
                      <div className="d-flex d-lg-block d-xl-flex align-items-end">
                        <h3 id="total-users" className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">ZMW 0.00</h3>
                      </div>
                      <span className="tx-11 tx-white mg-b-0 mg-t-5">0%</span>
                    </div>
                    <div className="card-footer py-0 pb-0">
                      <p className="tx-white pt-3"><strong>Number of Loans:</strong> 0</p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                  <div className="card bg-df-1">
                    <div className="card-body">
                      <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">DDAC</h6>
                      <div className="d-flex d-lg-block d-xl-flex align-items-end">
                        <h3 id="total-users" className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">ZMW 0.00</h3>
                      </div>
                      <span className="tx-11 tx-white mg-b-0 mg-t-5">0%</span>
                    </div>
                    <div className="card-footer py-0 pb-0">
                      <p className="tx-white pt-3"><strong>Number of Loans:</strong> 0</p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                  <div className="card bg-orange">
                    <div className="card-body">
                      <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">Mobile Money</h6>
                      <div className="d-flex d-lg-block d-xl-flex align-items-end">
                        <h3 id="total-users" className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">ZMW 0.00</h3>
                      </div>
                      <span className="tx-11 tx-white mg-b-0 mg-t-5">0%</span>
                    </div>
                    <div className="card-footer py-0 pb-0">
                      <p className="tx-white pt-3"><strong>Number of Loans:</strong> 0</p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                  <div className="card bg-danger">
                    <div className="card-body">
                      <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">Other</h6>
                      <div className="d-flex d-lg-block d-xl-flex align-items-end">
                        <h3 id="total-users" className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">ZMW 0.00</h3>
                      </div>
                      <span className="tx-11 tx-white mg-b-0 mg-t-5">0%</span>
                    </div>
                    <div className="card-footer py-0 pb-0">
                      <p className="tx-white pt-3"><strong>Number of Loans:</strong> 0</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mg-t-10">
                  <div className="card">
                    <div className="card-header d-flex align-items-center justify-content-between">
                      <h6 className="mg-b-0">Portfolio</h6>
                      <div className="d-flex tx-18">
                        <a href="" className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                      </div>
                    </div>
                    <div className="card-body">
                      <table id="user" className="table">
                        <thead>
                        <tr>
                            <th className="wd-10p">Customer</th>
                            <th className="wd-10p">ID Number</th>
                            <th className="wd-10p">Payment Date</th>
                            <th className="wd-10p">Payment Due</th>
                            <th className="wd-10p">Payment Received</th>
                            <th className="wd-10p">Interest Component</th>
                            <th className="wd-10p">Disbursement Date</th>
                            <th className="wd-10p">Employer</th>
                            <th className="wd-10p">Loan Type</th>
                            <th className="wd-10p">Collection Method</th>
                            <th className="wd-10p">Actions</th>
                        </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>

              </div>
            </div>
        </div>
    )
};

export default Collection;
