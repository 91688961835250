import React, { useEffect } from "react";
import 'datatables.net';
import 'datatables.net-responsive';
import AreaChart from "../../components/charts/AreaChart";
import PieChart from "../../components/charts/PieChart";
import $ from "jquery";
import { GET_DATA_LIST } from "../../api";
import { capitalize } from "../../utils/capitalize";

const Pipeline = () => {

    document.title = "Pipeline";

    useEffect(() => {
        var table = $('#user').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Loan Report</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10
        });
    })

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Pipeline</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Pipeline</h4>
                    </div>
                </div>

                <div className="row row-xs">

                    <div className="col-sm-12 col-lg-12 mg-b-20">
                        <div className="card bg-white-9">
                            <div className="d-flex justify-content-between p-3">
                                <h5 className="tx-normal tx-primary mg-b-0"><strong>ZMW 0.00</strong></h5>
                                <h5 className="tx-normal tx-color-02 mg-b-0"><strong>Number of Loans:</strong> 0</h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg">
                        <div className="card bg-secondary">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">KYC</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 id="total-users" className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">0</h3>
                                </div>
                                <span className="tx-11 tx-white mg-b-0 mg-t-5">ZMW 0</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg">
                        <div className="card bg-df-1">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">DUE DILIGENCE</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 id="total-users" className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">0</h3>
                                </div>
                                <span className="tx-11 tx-white mg-b-0 mg-t-5">ZMW 0</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg">
                        <div className="card bg-orange">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">CREDIT REVIEW</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 id="total-users" className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">0</h3>
                                </div>
                                <span className="tx-11 tx-white mg-b-0 mg-t-5">ZMW 0</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg">
                        <div className="card bg-warning">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">PENDING
                                    APPROVAL</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 id="total-users" className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">0</h3>
                                </div>
                                <span className="tx-11 tx-white mg-b-0 mg-t-5">ZMW 0</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg">
                        <div className="card bg-success">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">
                                    APPROVED</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 id="total-users" className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">0</h3>
                                </div>
                                <span className="tx-11 tx-white mg-b-0 mg-t-5">ZMW 0</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg">
                        <div className="card bg-danger">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">
                                    REJECTED</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 id="total-users" className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">0</h3>
                                </div>
                                <span className="tx-11 tx-white mg-b-0 mg-t-5">ZMW 0</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg">
                        <div className="card bg-primary">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">
                                    DISBURSED</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 id="total-users" className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">0</h3>
                                </div>
                                <span className="tx-11 tx-white mg-b-0 mg-t-5">ZMW 0</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mg-t-10">
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                          <h6 className="mg-b-0">Total Pipeline</h6>
                          <div className="d-flex tx-18">
                            <a href="" className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                          </div>
                        </div>
                        <div className="card-body">
                          <table id="user" className="table">
                            <thead>
                            <tr>
                                <th className="wd-10p">Loan ID</th>
                                <th className="wd-10p">Name</th>
                                <th className="wd-10p">Employer</th>
                                <th className="wd-10p">Designation</th>
                                <th className="wd-10p">Amount</th>
                                <th className="wd-10p">Tenure</th>
                                <th className="wd-10p">Loan Type</th>
                                <th className="wd-10p">Mobile #</th>
                                <th className="wd-10p">Credit Score</th>
                                <th className="wd-10p">Loan Officer</th>
                                <th className="wd-10p">Pipeline Aging</th>
                                <th className="wd-10p">Bucket Aging</th>
                                <th className="wd-10p">Actions</th>
                            </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    </div>

                </div>
            </div>
        </div>
    )
};

export default Pipeline;
