import React, { useEffect } from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import AreaChart from "../../components/charts/AreaChart";
import PieChart from "../../components/charts/PieChart";

const Dashboard = () => {

    document.title = "Dashboard";

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Dashboard</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-b-20">
                        <div className="row">
                            <div className="col-lg-8 col-xl-9">
                                <div className="card">
                                    <div
                                        className="card-header pd-y-20 d-md-flex align-items-center justify-content-between">
                                        <h6 className="mg-b-0">Loans</h6>
                                        <ul className="list-inline d-flex mg-t-20 mg-sm-t-10 mg-md-t-0 mg-b-0">
                                            <li className="list-inline-item d-flex align-items-center">
                                                <span className="d-block wd-10 ht-10 bg-df-1 rounded mg-r-5"></span>
                                                <span
                                                    className="tx-sans tx-uppercase tx-10 tx-medium tx-color-03">All Days</span>
                                            </li>
                                            <li className="list-inline-item d-flex align-items-center mg-l-5">
                                                <span className="d-block wd-10 ht-10 bg-df-2 rounded mg-r-5"></span>
                                                <span
                                                    className="tx-sans tx-uppercase tx-10 tx-medium tx-color-03">Budget</span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="card-body pos-relative pd-0">
                                        <div className="pos-absolute t-20 l-20 wd-xl-100p z-index-10">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <h3 className="tx-normal tx-rubik tx-spacing--2 mg-b-5">K0</h3>
                                                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-10">Total
                                                        Annual Revenue</h6>
                                                    <p className="mg-b-0 tx-12 tx-color-03">Total annual revenue for all
                                                        loans</p>
                                                </div>

                                                <div className="col-sm-4 mg-t-20 mg-sm-t-0">
                                                    <h3 className="tx-normal tx-rubik tx-spacing--2 mg-b-5">K0</h3>
                                                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-10">Monthly
                                                        Revenue</h6>
                                                    <p className="mg-b-0 tx-12 tx-color-03">Total Monthly revenue for
                                                        all loans</p>
                                                </div>

                                                <div className="col-sm-4 mg-t-20 mg-sm-t-0">
                                                    <h3 className="tx-normal tx-rubik tx-spacing--2 mg-b-5">K0</h3>
                                                    <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-10">Average
                                                        Monthly Revenue</h6>
                                                    <p className="mg-b-0 tx-12 tx-color-03">Average revenue per month
                                                        for all loans </p>
                                                </div>
                                            </div>
                                        </div>

                                        <AreaChart />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4 col-xl-3 mg-t-10 mg-lg-t-0">
                                <div className="card">
                                    <div className="card-header">
                                        <h6 className="mg-b-0">Loan Status</h6>
                                    </div>
                                    <div className="card-body pd-lg-25">
                                        <PieChart />
                                    </div>
                                    <div className="card-footer pd-20">
                                        <div className="row">
                                            <div className="col-6">
                                                <p
                                                    className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-5">Total Approved</p>
                                                <div className="d-flex align-items-center">
                                                    <div className="wd-10 ht-10 rounded-circle bg-pink mg-r-5"></div>
                                                    <h6 className="tx-normal tx-rubik mg-b-0">ZMW 0.00</h6>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <p
                                                    className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">Total Rejected</p>
                                                <div className="d-flex align-items-center">
                                                    <div className="wd-10 ht-10 rounded-circle bg-primary mg-r-5"></div>
                                                    <h6 className="tx-normal tx-rubik mg-b-0">ZM 0.00</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6 col-lg-4 col-xl-4 mg-t-10 mg-lg-t-0">
                                <div className="card">
                                    <div className="card-header">
                                        <h6 className="mg-b-0">Average Interest</h6>
                                    </div>
                                    <div className="card-body pd-lg-25">
                                        <PieChart />
                                    </div>
                                    <div className="card-footer pd-20">
                                        <div className="row">
                                            <div className="col-6">
                                                <p
                                                    className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 tx-nowrap mg-b-5">Civil Servant</p>
                                                <div className="d-flex align-items-center">
                                                    <div className="wd-10 ht-10 rounded-circle bg-pink mg-r-5"></div>
                                                    <h6 className="tx-normal tx-rubik mg-b-0">0</h6>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <p
                                                    className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">Parastatal</p>
                                                <div className="d-flex align-items-center">
                                                    <div className="wd-10 ht-10 rounded-circle bg-primary mg-r-5"></div>
                                                    <h6 className="tx-normal tx-rubik mg-b-0">0</h6>
                                                </div>
                                            </div>
                                            <div className="col-6 mg-t-20">
                                                <p
                                                    className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">Scheme</p>
                                                <div className="d-flex align-items-center">
                                                    <div className="wd-10 ht-10 rounded-circle bg-teal mg-r-5"></div>
                                                    <h6 className="tx-normal tx-rubik mg-b-0">0</h6>
                                                </div>
                                            </div>
                                            <div className="col-6 mg-t-20">
                                                <p
                                                    className="tx-10 tx-uppercase tx-medium tx-color-03 tx-spacing-1 mg-b-5">Special Force</p>
                                                <div className="d-flex align-items-center">
                                                    <div className="wd-10 ht-10 rounded-circle bg-orange mg-r-5"></div>
                                                    <h6 className="tx-normal tx-rubik mg-b-0">0</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-8 col-xl-8 mg-t-10 mg-lg-t-0 row">
                                <div className="col-sm-6 col-lg-6">
                                    <div className="card bg-facebook">
                                        <div className="card-body">
                                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">Current</h6>
                                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                                <h3 id="total-users"
                                                    className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">ZMW
                                                    0.00</h3>
                                            </div>
                                            <span className="tx-11 tx-white mg-b-0 mg-t-5">0%</span>
                                        </div>
                                        <div className="card-footer py-0 pb-0">
                                            <p className="tx-white pt-3"><strong>Number of Loans:</strong> 0</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-lg-6">
                                    <div className="card bg-df-1">
                                        <div className="card-body">
                                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">Deliquent
                                                -
                                                30 Days</h6>
                                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                                <h3 id="total-users"
                                                    className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">ZMW
                                                    0.00</h3>
                                            </div>
                                            <span className="tx-11 tx-white mg-b-0 mg-t-5">14%</span>
                                        </div>
                                        <div className="card-footer py-0 pb-0">
                                            <p className="tx-white pt-3"><strong>Number of Loans:</strong> 0</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-lg-6">
                                    <div className="card bg-orange">
                                        <div className="card-body">
                                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">Deliquent
                                                -
                                                60 Days</h6>
                                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                                <h3 id="total-users"
                                                    className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">ZMW
                                                    0.00</h3>
                                            </div>
                                            <span className="tx-11 tx-white mg-b-0 mg-t-5">0%</span>
                                        </div>
                                        <div className="card-footer py-0 pb-0">
                                            <p className="tx-white pt-3"><strong>Number of Loans:</strong> 0</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-lg-6">
                                    <div className="card bg-danger">
                                        <div className="card-body">
                                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-semibold mg-b-8 tx-white">Deliquent
                                                -
                                                90 Days</h6>
                                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                                <h3 id="total-users"
                                                    className="tx-normal tx-white mg-b-0 mg-r-5 lh-1">ZMW
                                                    0.00</h3>
                                            </div>
                                            <span className="tx-11 tx-white mg-b-0 mg-t-5">0%</span>
                                        </div>
                                        <div className="card-footer py-0 pb-0">
                                            <p className="tx-white pt-3"><strong>Number of Loans:</strong> 0</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Dashboard;
