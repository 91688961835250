import React, {useEffect, useState} from 'react';
import {GET_DATA_LIST} from '../../api';
import 'datatables.net';
import 'datatables.net-responsive';
import $ from "jquery";
import {Link} from "react-router-dom";
import cogoToast from "cogo-toast";
import {actionAdmin} from "request/apps";
import {capitalize} from "utils/capitalize";
import {useSelector} from "react-redux";

const AccessControl = () => {

    document.title = "Access Control";

    const id = useSelector((state) => state.auth.user.user.sub);
    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [editId, setEditId] = useState(0);

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {

        let table = $('#admin').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting admin list</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_DATA_LIST, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "admins",
                        fieldname: "name",
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success : function(res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.name
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.email
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return capitalize(data.role)
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return `${data.permission.create ? "C": ""}${data.permission.update ? "U": ""}${data.permission.delete ? "D": ""}${data.permission.approve ? "A": ""}`;
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_user"><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_user"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                },
                    "targets": 4
                }
            ]
        });

        table.on('click', '.delete_user', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();

            const error = {
                position: "top-right",
                hideAfter: 5
            };

            if(extract_td.raw._id === id) {
                cogoToast.error('Sorry, this logged account cannot be deleted.', error);
                return false;
            }

            const options = {
                position: "top-right",
                hideAfter: 0
            };

            let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

            let raw = {
                id: extract_td.raw._id,
                action: "delete"
            }

            actionAdmin(raw).then((result) => {
                hide();
                if(!result.error) {
                    reloadTable();
                    cogoToast.success(result.data, {position: "top-right"});
                }
            });
        });

        table.on('click', '.edit_user', function(e) {
            e.preventDefault();

            let name = $("#name");
            let email = $("#email");
            let extract_td = table.row($(this).closest('tr')).data();

            setEditForm(true);
            setEditId(extract_td.raw._id);

            $('#admin_form').parsley();

            name.prop('disabled', true);
            email.prop('disabled', true);
            $("#password").prop('required', false);

            name.val(extract_td.raw.name);
            email.val(extract_td.raw.email);
            $('#b1a').prop({checked: extract_td.raw.permission.create});
            $('#b2a').prop({checked: extract_td.raw.permission.update});
            $('#b3a').prop({checked: extract_td.raw.permission.delete});
            $('#b4a').prop({checked: extract_td.raw.permission.approve});

            $("#modalUser").modal("show");
        });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        let name = $('#name').val();
        let email = $('#email').val();
        let password = $('#password').val();
        let create = $('#b1a').is(':checked');
        let update = $('#b2a').is(':checked');
        let _delete = $('#b3a').is(':checked');
        let approve = $('#b4a').is(':checked');

        setStateReady(true);

        if(editForm) {
            let raw = {
                action: "update",
                id: editId,
                name: name,
                email: email,
                password: password,
                permission: {
                    create: create,
                    update: update,
                    delete: _delete,
                    approve: approve
                }
            };

            actionAdmin(raw).then((result) => {
                setStateReady(false);

                if(!result.error) {
                    cogoToast.success(result.data, {position: "top-right", hideAfter: 5});
                    resetForm("modalUser", "admin_form");
                    reloadTable();
                }
            });

            return false;
        }

        let raw = {
            action: "create",
            name: name,
            email: email,
            password: password,
            permission: {
                create: create,
                update: update,
                delete: _delete,
                approve: approve
            }
        };

        actionAdmin(raw).then((result) => {
            setStateReady(false);

            if(!result.error) {
                cogoToast.success(result.data, {position: "top-right", hideAfter: 5});
                resetForm("modalUser", "admin_form");
                reloadTable();
            }
        });
    };

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const handleShowModal = () => {
        setEditForm(false);
        $('#admin_form').parsley();
        $("#modalUser").modal("show");
    };

    const reloadTable = () => {
        $('#admin').DataTable().ajax.reload(null, false);
    };

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Access Control</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Access Control</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-user-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">User List</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="admin" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Email</th>
                                        <th className="wd-20p">Role</th>
                                        <th className="wd-20p">Permission</th>
                                        <th className="wd-20p">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="modalUser" tabIndex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </a>
                                    <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalUser", "admin_form")} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit User" : "Create User"}</h4><p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} user to have access to the console</p></div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="admin_form" className="parsley-style-1" onSubmit={handleSubmit.bind()}>
                                        <div id="nameWrapper" className="form-group parsley-input">
                                            <label>Full Name<span className="tx-danger">*</span></label>
                                            <input id="name" type="text" className="form-control"
                                                   placeholder="Enter full name"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#nameWrapper" required/>
                                        </div>

                                        <div id="emailWrapper" className="form-group parsley-input">
                                            <label>Email Address<span className="tx-danger">*</span></label>
                                            <input id="email" type="email" className="form-control"
                                                   placeholder="Enter valid email address"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#emailWrapper" required/>
                                        </div>

                                        <div id="passwordWrapper" className="form-group parsley-input">
                                            <label>Password<span className="tx-danger">*</span></label>
                                            <input id="password" type="password" className="form-control"
                                                   placeholder="Enter new password"
                                                   autoComplete="none"
                                                   data-parsley-class-handler="#passwordWrapper"/>
                                        </div>

                                        <div className="form-group">
                                            <div data-label="Access Privilege" className="df-example mg-b-5">
                                                <div id="cbWrapper2" className="parsley-checkbox pos-relative">
                                                    <div className="custom-control custom-checkbox">
                                                        <input className="custom-control-input" type="checkbox" name="_form[]"
                                                               data-parsley-mincheck="1"
                                                               data-parsley-class-handler="#cbWrapper2"
                                                               data-parsley-errors-container="#cbErrorContainer2"
                                                               required
                                                               data-parsley-multiple="_form" id="b1a"/>
                                                        <label className="custom-control-label" htmlFor="b1a">Create</label>
                                                    </div>

                                                    <div className="custom-control custom-checkbox">
                                                        <input className="custom-control-input" type="checkbox"
                                                               name="_form[]"
                                                               data-parsley-multiple="_form"
                                                               id="b2a"/>
                                                        <label className="custom-control-label" htmlFor="b2a">Update</label>
                                                    </div>

                                                    <div className="custom-control custom-checkbox">
                                                        <input className="custom-control-input" type="checkbox"
                                                               name="_form[]"
                                                               data-parsley-multiple="_form"
                                                               id="b3a"/>
                                                        <label className="custom-control-label" htmlFor="b3a">Delete</label>
                                                    </div>

                                                    <div className="custom-control custom-checkbox">
                                                        <input className="custom-control-input" type="checkbox"
                                                               name="_form[]"
                                                               data-parsley-multiple="_form"
                                                               id="b4a"/>
                                                        <label className="custom-control-label" htmlFor="b4a">Approve Loan</label>
                                                    </div>

                                                    <div id="cbErrorContainer2" className="wd-100p pos-absolute b-0 l-0"/>
                                                </div>
                                            </div>
                                        </div>

                                        {stateReady ? <button disabled className="btn btn-brand-02 btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : editForm ? <button className="btn btn-brand-02 btn-block">Update Account</button> : <button className="btn btn-brand-02 btn-block">Create Account</button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AccessControl;
