import axios from "axios";
import {
    GET_REGISTERED_COUNT_URL,
    DELETE_USER_URL,
    ACTION_PERSONAL_CLIENT_URL,
    ACTION_CLIENT_URL,
    ACTION_CORPORATE_CLIENT_URL,
    CUSTOMER_URL
} from "../api";
import errorHandler from "../utils/errorHandler";

//New Route
export const customer = (data, method) => {
    return axios({
        method: method,
        url: CUSTOMER_URL,
        data
    }).then((response) => {
        return {
            payload: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-center");
        return {
            error: true
        }
    });
};

//Old Route
export const getRegisteredCount = () => {
    return axios.get(GET_REGISTERED_COUNT_URL).then((response) => {
        return response.data;
    }).catch((error) => {
        errorHandler(error, "top-center");
    });
};

export const actionUser = (id) => {
    return axios.post(DELETE_USER_URL, {id}).then((response) => {
        return {
            data: response.data,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-center");
        return {
            error: true
        }
    });
};

export const addPersonalClient = (raw) => {
    return axios.post(ACTION_PERSONAL_CLIENT_URL, raw).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-center");
        return {
            error: true
        }
    });
};

export const addClient = (raw) => {
    return axios.post(ACTION_CLIENT_URL, raw).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-center");
        return {
            error: true
        }
    });
};

export const addCorporateClient = (raw) => {
    return axios.post(ACTION_CORPORATE_CLIENT_URL, raw).then((response) => {
        return {
            payload: response.data,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-center");
        return {
            error: true
        }
    });
};
