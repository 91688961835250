import React, { useEffect, useState } from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_COLLECTION_DATA} from '../../api';
import $ from "jquery";
import {capitalize} from "../../utils/capitalize";
import moment from 'moment';
import {getRegisteredCount, addPersonalClient, addCorporateClient, addClient, customer} from "../../request";
import generateId from "../../utils/generateChar";
const AWS = require('aws-sdk');

const bucket = new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
    region: process.env.REACT_APP_REGION
});

const Customer = () => {

    document.title = "Customer";

    const token = localStorage.getItem('jwtToken');
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState("personal");

    useEffect(() => {
        getCounter();
        let customer_table = $('#td-customer').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Customer Report</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "customer",
                        fieldname: "nrc_id",
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return `${capitalize(data.first_name)} ${data.middle_name ? capitalize(data.middle_name) : ""} ${capitalize(data.last_name)}`;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.nrc_id;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return `+${data.mobile_number}`;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return capitalize(data.gender);
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date.string).format("Do MMM, YYYY");
                    },
                    "targets": 4
                }
            ]
        });
    }, []);

    const getCounter = () => {
        getRegisteredCount().then((data) => {
            $(".total-clients").text(data.total);
            $(".total-personal").text(data.total);
            $(".total-corporate").text(data.total_company);
        });
    };

    const addPersonal = () => {
        $("#type").val(type);
        $("#idType").val("nrc");
        $("#c_idType").val("nrc");
        $("#personalForm").parsley();
        $("#kinForm").parsley();
        $("#uploadForm").parsley();
        $("#modalPersonal").modal("show");
    };

    const handleResetForm = (id, form) => {
        let parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        parsley.reset();
        parsley.destroy();
        $(`#${id}`).modal("hide");
        $(".modal-backdrop").remove();
    };

    const S3upload = (file) =>{
        const params = {
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Key: generateId(50),
            Body: file,
            ContentType: file.type
        };

        return bucket.upload(params, function (err, data) {
            if (err) return console.log(err);
            console.log(data);
            return data;
        }).on('httpUploadProgress', function(progress) {
            let progressPercentage = Math.round(progress.loaded / progress.total * 100);
            // setUploadPercentage(`Uploading ${(percentage + progressPercentage/totalFiles).toFixed(1)}%`);
        }).promise();
    }

    const personalSubmit = (e) => {
        e.preventDefault();
        $('#kyc .active').parent().next('li').find('a').trigger('click');
    }

    const kycSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        let raw = {
            first_name: $('#per_first_name').val(),
            middle_name: $('#per_middle_name').val(),
            last_name: $('#per_last_name').val(),
            mobile_number: $('#per_mobile_number').val(),
            dob: $('#dob').val() || new Date().toISOString(),
            nrc_id: $('#nrc_number').val(),
            email: $('#email_address').val(),
            marital_status: $('#marital_status').val(),
            gender: $('#gender').val(),
            physical_address: {
                address: $('#per_address').val(),
                district: $('#per_district').val(),
                city: $('#per_city').val(),
                province: $('#per_province').val()
            },
            next_of_kin: {
                name: `${$('#kin_first_name').val()} ${$('#kin_last_name').val()}`,
                relationship: $('#relationship').val(),
                mobile_number: $('#kin_mobile_number').val(),
                physical_address: {
                    address: $('#kin_address').val(),
                    district: $('#kin_district').val(),
                    city: $('#kin_city').val(),
                    province: $('#kin_province').val()
                }
            }
        }

        customer(raw, "post").then((response) => {
            setLoading(false);
            if(!response.error) {
                getCounter();
                handleResetForm("modalPersonal", "personalForm");
                $('#td-customer').DataTable().ajax.reload()
            }
        });
    }

    const kinSubmit = (e) => {
        e.preventDefault();
        $('#kyc .active').parent().next('li').find('a').trigger('click');
    }

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Customers</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Customers</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={addPersonal.bind()}><i className="wd-10 mg-r-5 fa fa-user-plus"></i> Add
                            New
                        </button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="row row-xs w-100">
                        <div className="col-md-12 mg-t-10">
                            <div className="card">
                                <div className="card-header d-flex align-items-center justify-content-between">
                                    <h6 className="mg-b-0">Customers</h6>
                                    <div className="d-flex tx-18">
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            addPersonal();
                                        }} className="link-03 lh-0 mg-r-10"><i className="icon ion-md-add"></i></a>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            $("#td-customer").DataTable().ajax.reload();
                                            getCounter();
                                        }} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <table id="td-customer" className="table">
                                        <thead>
                                        <tr>
                                            <th className="wd-20p">Name</th>
                                            <th className="wd-20p">NRC</th>
                                            <th className="wd-25p">Mobile Number</th>
                                            <th className="wd-15p">Gender</th>
                                            <th className="wd-15p">Date Created</th>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalPersonal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mx-wd-sm-650" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal"
                               aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </a>
                            <div className="media-body"><a href="javascript:void(0)" role="button" className="close pos-absolute t-15 r-15" onClick={handleResetForm.bind(null, "modalPersonal", "personalForm")} aria-label="Close"><span aria-hidden="true">×</span></a><h4
                                className="tx-18 tx-sm-20 mg-b-2">Customer Form</h4><p
                                className="tx-13 tx-color-02 mg-b-0">Create user to have access to the app</p></div>
                        </div>
                        <div className="modal-body pd-sm-t-10 pd-sm-b-40 pd-sm-x-30">
                            <div className="nav-wrapper mg-b-20 tx-13">
                                <div>
                                    <ul id="kyc" className="nav nav-line nav-fill tx-medium nav-disabled">
                                        <li className="nav-item"><a href="#personal" className="nav-link active" data-toggle="tab"><i className="ion-md-information-circle-outline"/> Basic Personal</a></li>
                                        <li className="nav-item"><a href="#kin" className="nav-link" data-toggle="tab"><i className="ion-md-document"/> Next of Kin</a></li>
                                        <li className="nav-item"><a href="#upload" className="nav-link" data-toggle="tab"><i className="ion-md-checkmark-circle-outline"/> Upload Document</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div id="personal" className="tab-pane fade active show">
                                    <form id="personalForm" className="parsley-style-1"
                                          onSubmit={personalSubmit.bind()}>
                                        <div className="row row-sm">
                                            <div id="firstWrapper" className="form-group parsley-input col-sm-4">
                                                <label>First Name<span className="tx-danger">*</span></label>
                                                <input id="per_first_name" className="form-control"
                                                       placeholder="Enter first name"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#firstWrapper" required/>
                                            </div>

                                            <div id="middleWrapper" className="form-group parsley-input col-sm-4">
                                                <label>Middle Name (Optional)</label>
                                                <input id="per_middle_name" className="form-control"
                                                       placeholder="Enter middle name"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#middleWrapper"/>
                                            </div>

                                            <div id="lastWrapper" className="form-group parsley-input col-sm-4">
                                                <label>Last Name<span className="tx-danger">*</span></label>
                                                <input id="per_last_name" className="form-control"
                                                       placeholder="Enter last name"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#lastWrapper" required/>
                                            </div>

                                            <div id="genderWrapper" className="form-group parsley-input col-sm-6">
                                                <label>Gender<span className="tx-danger">*</span></label>
                                                <select className="custom-select" id="gender" data-parsley-class-handler="#genderWrapper" required>
                                                    <option value="">Select</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                    <option value="other">Other</option>
                                                </select>
                                            </div>

                                            <div id="maritalWrapper" className="form-group parsley-input col-sm-6">
                                                <label>Marital Status<span className="tx-danger">*</span></label>
                                                <select className="custom-select" id="marital_status" data-parsley-class-handler="#maritalWrapper" required>
                                                    <option value="">Select</option>
                                                    <option value="married">Married</option>
                                                    <option value="single">Single</option>
                                                    <option value="widowed">Widowed</option>
                                                </select>
                                            </div>

                                            <div id="phoneWrapper" className="form-group parsley-input col-sm-6">
                                                <label>Mobile Number<span className="tx-danger">*</span></label>
                                                <input id="per_mobile_number" className="form-control"
                                                       placeholder="Enter mobile number"
                                                       type="number"
                                                       autoComplete="off"
                                                       minLength={10}
                                                       data-parsley-class-handler="#phoneWrapper" required/>
                                            </div>

                                            <div id="emailWrapper" className="form-group parsley-input col-sm-6">
                                                <label>Email<span className="tx-danger">*</span></label>
                                                <input id="email_address" className="form-control"
                                                       placeholder="Enter last name"
                                                       type="email"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#emailWrapper" required/>
                                            </div>

                                            <div id="nrcWrapper" className="form-group parsley-input col-sm-12">
                                                <label>NRC Number<span className="tx-danger">*</span></label>
                                                <input id="nrc_number" className="form-control"
                                                       placeholder="Enter nrc Number"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#nrcWrapper" required/>
                                            </div>

                                            <div id="streetWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Street Address<span className="tx-danger">*</span></label>
                                                <input id="per_address" className="form-control"
                                                       placeholder="Enter street Name"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#streetWrapper" required/>
                                            </div>

                                            <div id="areaWrapper" className="form-group parsley-input col-sm-12">
                                                <label>District<span className="tx-danger">*</span></label>
                                                <input id="per_district" className="form-control"
                                                       placeholder="Enter district"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#areaWrapper" required/>
                                            </div>

                                            <div id="townWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Town<span className="tx-danger">*</span></label>
                                                <input id="per_city" className="form-control"
                                                       placeholder="Enter town"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#townWrapper" required/>
                                            </div>

                                            <div id="provinceWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Province<span className="tx-danger">*</span></label>
                                                <select className="custom-select" id="per_province" data-parsley-class-handler="#provinceWrapper" required>
                                                    <option value="">Select</option>
                                                    <option value="lusaka">Lusaka</option>
                                                    <option value="copperbelt">Copperbelt</option>
                                                    <option value="central">Central</option>
                                                    <option value="eastern">Eastern</option>
                                                    <option value="western">Western</option>
                                                    <option value="luapula">Luapula</option>
                                                    <option value="northern">Northern</option>
                                                    <option value="north western">North Western</option>
                                                    <option value="southern">Southern</option>
                                                </select>
                                            </div>
                                        </div>
                                        <button className="btn btn-brand-01 float-right btnNextFront">Next <i className="ion-md-arrow-forward"/></button>
                                    </form>
                                </div>

                                <div id="kin" className="tab-pane fade">
                                    <form id="kinForm" className="parsley-style-1" onSubmit={kinSubmit.bind()}>
                                        <div className="row row-sm">
                                            <div id="firstWrapper" className="form-group parsley-input col-sm-6">
                                                <label>First Name<span className="tx-danger">*</span></label>
                                                <input id="kin_first_name" className="form-control"
                                                       placeholder="Enter first name"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#firstWrapper" required/>
                                            </div>

                                            <div id="lastWrapper" className="form-group parsley-input col-sm-6">
                                                <label>Last Name<span className="tx-danger">*</span></label>
                                                <input id="kin_last_name" className="form-control"
                                                       placeholder="Enter last name"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#lastWrapper" required/>
                                            </div>

                                            <div id="relationshipWrapper"
                                                 className="form-group parsley-input col-sm-12">
                                                <label>Relationship<span className="tx-danger">*</span></label>
                                                <select className="custom-select" id="relationship" data-parsley-class-handler="#relationshipWrapper" required>
                                                    <option value="">Select</option>
                                                    <option value="father">Father</option>
                                                    <option value="mother">Mother</option>
                                                    <option value="sister">Sister</option>
                                                    <option value="brother">Brother</option>
                                                    <option value="cousin">Cousin</option>
                                                </select>
                                            </div>

                                            <div id="phoneWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Mobile Number<span className="tx-danger">*</span></label>
                                                <input id="kin_mobile_number" className="form-control"
                                                       placeholder="Enter phone number"
                                                       type="number"
                                                       autoComplete="off"
                                                       minLength={10}
                                                       data-parsley-class-handler="#phoneWrapper" required/>
                                            </div>

                                            <div id="streetWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Street Address<span className="tx-danger">*</span></label>
                                                <input id="kin_address" className="form-control"
                                                       placeholder="Enter street Name"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#streetWrapper" required/>
                                            </div>

                                            <div id="areaWrapper" className="form-group parsley-input col-sm-12">
                                                <label>District<span className="tx-danger">*</span></label>
                                                <input id="kin_district" className="form-control"
                                                       placeholder="Enter district"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#areaWrapper" required/>
                                            </div>

                                            <div id="townWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Town<span className="tx-danger">*</span></label>
                                                <input id="kin_city" className="form-control"
                                                       placeholder="Enter town"
                                                       type="text"
                                                       autoComplete="off"
                                                       data-parsley-class-handler="#townWrapper" required/>
                                            </div>

                                            <div id="provinceWrapper" className="form-group parsley-input col-sm-12">
                                                <label>Province<span className="tx-danger">*</span></label>
                                                <select className="custom-select" id="kin_province" data-parsley-class-handler="#provinceWrapper" required>
                                                    <option value="">Select</option>
                                                    <option value="lusaka">Lusaka</option>
                                                    <option value="copperbelt">Copperbelt</option>
                                                    <option value="central">Central</option>
                                                    <option value="eastern">Eastern</option>
                                                    <option value="western">Western</option>
                                                    <option value="luapula">Luapula</option>
                                                    <option value="northern">Northern</option>
                                                    <option value="north western">North Western</option>
                                                    <option value="southern">Southern</option>
                                                </select>
                                            </div>

                                        </div>
                                        <a className="btn btn-dark float-left btnPrevious text-white" onClick={() => $('#kyc .active').parent().prev('li').find('a').trigger('click')}><i className="ion-md-arrow-back"/> Back</a>
                                        <button className="btn btn-brand-01 float-right btnNextFront">Next <i className="ion-md-arrow-forward"/></button>
                                    </form>
                                </div>

                                <div id="upload" className="tab-pane fade">
                                    <form id="uploadForm" className="parsley-style-1" onSubmit={kycSubmit.bind()}>
                                        <div className="row row-sm">
                                        <div className="form-group col-sm-12">
                                                <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Upload Size Photo</label>
                                                <input id="selfie" type="file" className="form-control"
                                                       data-parsley-filemaxmegabytes="1"
                                                       data-parsley-trigger="change"
                                                       accept="image/*"
                                                       data-parsley-filemimetypes="image/jpeg"
                                                       required
                                                />
                                            </div>

                                            <div className="form-group col-sm-12">
                                                <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Upload NRC Back Photo</label>
                                                <input id="nrc_front" type="file" className="form-control"
                                                       data-parsley-filemaxmegabytes="1"
                                                       data-parsley-trigger="change"
                                                       accept="image/*"
                                                       data-parsley-filemimetypes="image/jpeg"
                                                       required
                                                />
                                            </div>

                                            <div className="form-group col-sm-12">
                                                <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Upload NRC Front Photo</label>
                                                <input id="nrc_back" type="file" className="form-control"
                                                       data-parsley-filemaxmegabytes="1"
                                                       data-parsley-trigger="change"
                                                       accept="image/*"
                                                       data-parsley-filemimetypes="image/jpeg"
                                                       required
                                                />
                                            </div>
                                        </div>
                                        <a className="btn btn-dark float-left btnPrevious text-white" onClick={() => $('#kyc .active').parent().prev('li').find('a').trigger('click')}><i className="ion-md-arrow-back"/> Back</a>
                                        {loading ? <button disabled className="btn btn-success float-right btnNextFront"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : <button className="btn btn-brand-01 float-right btnNextFront">Submit <i className="ion-md-arrow-forward"/></button>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Customer;
